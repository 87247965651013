<div class="ps-0 wrapper mt-3">
  <div class="header-container">
    <div class="right-section">
      <span class="me-2">
        <button
          class="hidden-sm bg-slit-grey border-radius-80 zindex-one notification"
          mat-icon-button
          [matMenuTriggerFor]="actionNotificationDropDown">
          <div>
            <img class="mt-2" height="24px" width="24px" src="assets/images/notification-icon.svg" alt="Notification" />
            <mat-icon
              *ngIf="actionNotificationCount > 0"
              class="green-badge"
              [matBadge]="(_socket.updateActionNotificationCount | async) == null ? (actionNotificationCount > 99 ? '99+' : actionNotificationCount) : (_socket.updateActionNotificationCount | async)
              "></mat-icon>
          </div>
        </button>
        <mat-menu
          xPosition="before"
          yPosition="above"
          #actionNotificationDropDown="matMenu"
          backdropClass="notificationDropdown"
          class="bg-white border-radius-12 mw-500">
          <div class="p-3">
            <span class="fs-18 fw-500 color-primary-black">Notification({{ actionNotificationCount }})</span>
          </div>
          <div class="notificationscroll px-2">
            <ng-container *ngIf="actionNotifications?.length; else notFound">
              <ng-container *ngFor="let notification of actionNotifications">
                <div
                  [ngClass]="notification?.read_status != true ? 'bg-monochromatic' : 'bg-dim-white'"
                  class="d-flex align-items-start justify-content-between p-3 mb-2 border-radius-8 mw-450 mx-2">
                  <div
                    (click)="navigateToActionNotification(notification)"
                    [matTooltip]="
                      !notification?.moved_to_next_status ? '' : 'The candidate has moved to the next stage.'
                    "
                    class="d-flex align-items-start justify-content-between w-100 cursor-pointer">
                    <div class="w-100">
                      <div class="d-flex justify-content-between mb-1 w-100">
                        <p class="m-0 fs-14 fw-500">
                          <!-- {{ notification.notification_by_name | titlecase}} | -->
                          <span>{{ notification.title | titlecase }}</span>
                        </p>
                        <p class="m-0 d-flex text-center">
                          <span class="d-block fs-12 fw-400 white-space-nowrap">
                            {{ dateAgo(notification?.createdAt) }}
                          </span>
                          <span *ngIf="notification?.unread_msg_count > 0" class="green-dot ms-1"></span>
                        </p>
                      </div>
                      <div class="">
                        <p class="m-0 fs-12 fw-400 me-4 w-75">
                          {{ notification?.message | slice: 0 : maxLength }}
                          <span *ngIf="notification?.message?.length > maxLength">...</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <ng-template #notFound>
            <div class="empty-table mh-185">
              <div class="content">
                <img src="assets/images/hopeless_cat.svg" alt="Empty" />
                <h6 class="fs-15 fw-500 color-midnight">Sorry No Data Found!</h6>
              </div>
            </div>
          </ng-template>
          <div class="d-flex justify-content-between box-shadow-dark m-0 py-3 px-3">
            <button
              routerLink="/notifications/action-notifications"
              class="line-height-28 fs-14 fw-500 mat-button color-blue-ribbon mat-button-outline">
              View All
            </button>
          </div>
        </mat-menu>
      </span>

      <!-- Chat notifications start -->

      <button
        class="hidden-sm bg-slit-grey border-radius-80 zindex-one notification"
        mat-icon-button
        [matMenuTriggerFor]="notificationDrop">
        <div>
          <img class="mt-2" height="24px" width="24px" src="assets/images/message-circle.svg" alt="Notification" />
          <mat-icon *ngIf="notificationCount > 0" class="green-badge" [matBadge]="notificationCount"></mat-icon>
        </div>
      </button>
      <mat-menu
        xPosition="before"
        yPosition="above"
        #notificationDrop="matMenu"
        backdropClass="notificationDropdown"
        class="bg-white border-radius-12 mw-500">
        <div class="p-3">
          <span class="fs-18 fw-500 color-primary-black">Notification({{ notificationCount }})</span>
        </div>
        <div class="notificationscroll px-2">
          <ng-container *ngIf="notifications?.length; else notFound">
            <ng-container *ngFor="let notification of notifications">
              <div
                [ngClass]="notification?.unread_msg_count > 0 ? 'bg-solitude' : 'bg-cloud-grey'"
                class="d-flex align-items-start justify-content-between p-3 mb-2 border-radius-8 mw-450 mx-2">
                <div
                  (click)="navigateToChat(notification)"
                  class="d-flex align-items-start justify-content-between w-100 cursor-pointer">
                  <div class="w-100">
                    <div class="d-flex justify-content-between mb-1 w-100">
                      <p class="m-0 fs-14 fw-500">
                        <span>
                          {{ notification.sender_name | titlecase }} | {{ notification.vendor_name | titlecase }}
                        </span>
                      </p>
                      <p class="m-0 d-flex text-center">
                        <span class="d-block fs-12 fw-400 white-space-nowrap">
                          {{ dateAgo(notification?.createdAt) }}
                        </span>
                        <span *ngIf="notification?.unread_msg_count > 0" class="green-dot ms-1"></span>
                      </p>
                    </div>
                    <div class="">
                      <p class="m-0 fs-12 fw-400 me-4 w-75">
                        {{ notification?.message | slice: 0 : maxLength }}
                        <span *ngIf="notification?.message?.length > maxLength">...</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <ng-template #notFound>
          <div class="empty-table mh-185">
            <div class="content">
              <img src="assets/images/hopeless_cat.svg" alt="Empty" />
              <h6 class="fs-15 fw-500 color-midnight">Sorry No Data Found!</h6>
            </div>
          </div>
        </ng-template>
        <div class="d-flex justify-content-between box-shadow-dark m-0 py-3 px-3">
          <button
            routerLink="/notifications/list"
            class="line-height-28 fs-14 fw-500 mat-button color-blue-ribbon mat-button-outline">
            View All
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</div>

import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { MENU_ITEMS } from '../../../side-nav-menu';
import { UtilityService } from '../../services/utility.service';
import { AppState } from '../../store/app.state';
import { autoLogout } from '../../store/auth-state/auth.actions';
import { isAuthenticated } from '../../store/auth-state/auth.selector';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AlertMessagesComponent } from 'src/app/shared/dialogs/alert-messages/alert-messages.component';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<boolean>();
  menuList: MenuItem[] = MENU_ITEMS;
  queryParams: any;
  currentRoute!: string;
  loggedInUserDetails: any;
  isAuthenticated: Observable<boolean> | undefined;
  profilePic!: string;
  userName!: string;
  role!: string;
  permissions!: string;
  userDetailsSubscription!: Subscription;
  notificationCount: number = 0;
  notifications: any;
  actionNotificationCount!: string
  actionNotifications: any;
  maxLength: number = 80; // Set your desired maximum length
  showFull: boolean = false;
  element: any;
  currentURL: string = environment?.currentEnv;


  items: MenuItem[] = [];
  filteredMenuList: any;
  

  // action
  constructor(
    public router: Router,
    private store: Store<AppState>,
    public utility: UtilityService,
    private _activatedRoute: ActivatedRoute,
    private route: Router,
    public dialog: MatDialog,
    // private toastr: ToastrService,

    // private _websocket: WebservicesService,
    // public _socket: SocketService
  ) {
    this.loggedInUserDetails = JSON.parse(localStorage.getItem('userData') || '{}');
    // let isTmtAssociate = this.loggedInUserDetails.role[0]?.role_name
    // this.isNavlinkUpdate(isTmtAssociate)
  }
  ngOnInit(): void {
    this.checkRoleBase()
    let firstIndex = window.location.pathname.split('/').filter((x) => x != '')[0];
    let secondIndex = window.location.pathname.split('/').filter((x) => x != '')[1];
    this.currentRoute = `/${firstIndex}/${secondIndex}`;
    this.loggedInUserDetails = JSON.parse(localStorage.getItem('userData') || '{}');
    let user = {
      ...this.loggedInUserDetails,
    }
    this.utility.updatedUserDetails(
      user
    );
    this.userDetailsSubscription = this.utility.userDetails.subscribe((details) => {
      if (details) {
        this.userName = details.first_name + ' ' + details.last_name ? details.first_name + ' ' + details.last_name : this.loggedInUserDetails.first_name + ' ' + this.loggedInUserDetails.last_name;
        this.profilePic = details.profile_pic ? details.profile_pic : this.loggedInUserDetails.profile_pic;
        this.role = details.role[0].role_slug;
        this.permissions = details?.permissions;
      }
    });
    this.currentRoute = this.router.url;
    this.isAuthenticated = this.store.select(isAuthenticated);
  }

  checkRoleBase() {
    this.filteredMenuList = this.menuList;
    const localUser: any = JSON.parse(localStorage.getItem('userData') || '');
    const role = localUser?.role[0]?.role_name;
    if (role === 'tp_associate') {
      this.filteredMenuList = this.menuList.filter(
        (item: any) => item?.alt !== 'TMT Delegation'
      );
    } else {
      this.filteredMenuList = this.menuList.filter(
        (item: any) => item?.alt !== 'Associate TMT Delegation'
      );
    }

  }
  // isNavlinkUpdate(isTmtAssociate:string){
  //   this.menuList.map((item:any)=>{
  //     if(item.label==='TMT Dashboard'){
  //       item.navLink=isTmtAssociate==='tp_associate' ? '/tmt/associate':'/tmt/tmt-dashboard'
  //     }
  //   })
  // }
  // tmt/associate
  // routerWithQueryParams(routeLink: string, queryParams?: any) {
  //   let isTmtAssociate = this.loggedInUserDetails.role[0]?.role_name
  //   if(routeLink==='/tmt/tmt-dashboard'){
  //     if(isTmtAssociate==='tp_associate'){
  //       this.isNavlinkUpdate(isTmtAssociate)
  //       this.router.navigate(['/tmt/associate'] ,{ queryParams: { offset: 0, limit: 10 } })
  //     }else{
  //       this.router.navigate(['/tmt/tmt-dashboard'] ,{ queryParams: { offset: 0, limit: 10 } })
  //     }
  //   }else{
  //   this.queryParams = queryParams;
  //   this.currentRoute = routeLink?.split('?')[0];
  //   if (this.router.url.split('?')[0] != routeLink) {

  //     if (routeLink == '/individual-developer' && this.role == "talent management associate") {

  //       this.router.navigate(['/individual-developer/benchpool-list'], { queryParams: { offset: 0, limit: 10, pool_status: 'benchpool' } });
  //     } else {

  //       this.router.navigate([routeLink], { queryParams: this.queryParams });
  //     }
  //   }
  //   }
  // // }
  // navigateToChat(notification: any) {
  //   const projectId = this.utility.base64Encode(notification?.project_id);

  //   this.router.navigate(['/chat/', projectId, 'redirect'], { queryParams: { param1: 'value1', param2: 'value2' } });


  logout(event: Event) {
    localStorage.clear()
    event.preventDefault();
    this.store.dispatch(autoLogout());
  }




  ngOnDestroy(): void {
    this.userDetailsSubscription?.unsubscribe();
  };
  toggleReadMore() {
    this.showFull = !this.showFull;
  }


  activeRoute = true

  navigateToMenu(route: any, queryParams?: any, label?: any) {
    
    this.activeRoute = false;
    this.router.navigate([`${route}`], { queryParams });
    this.activeRoute = true;


  }
  openLogoutModel(event: any) {
    let dialogRef = this.dialog.open(AlertMessagesComponent, {
      width: '35%',
      height: 'auto',
      data: { heading: 'Logout', description: 'Are you sure want to Logout ?', firstBtn: 'No', secondBtn: 'Yes' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data == 'Success') {

        localStorage.clear()
        event.preventDefault();
        this.store.dispatch(autoLogout());
      }
    });
  }
}

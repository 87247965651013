
    export const environment: any = {
        production: false,
        GOOGLE_PLACES_API: '',
        IP_REGISTRY_KEY: '',
        LINKEDIN_API_KEY: '',
        firebase: {
            apiKey: 'AIzaSyAlDP0cs0-pGQ_ztIrVzsYCOj3qLGT3X4I',
            authDomain: 'vendor-6de47.firebaseapp.com',
            projectId: 'vendor-6de47',
            storageBucket: 'vendor-6de47.appspot.com',
            messagingSenderId: '650765512471',
            appId: '1:650765512471:web:19d63e058eba5c0a18d152'
        },
        analytics_service: 'https://staging-api.supersourcing.com/analytics-service/api/v1/',
        developer_base_url: 'https://staging-api.supersourcing.com/user-management-service/api/v1/engineers/',
        team_base_url: 'https://g2q743v1uj.execute-api.ap-south-1.amazonaws.com/staging/api/v1/',
        user_management_url: 'https://staging-api.supersourcing.com/user-management-service/api/v1/',
        user_management_url_v2: 'https://staging-api.supersourcing.com/user-management-service/api/v2/',
        asset_base_url: 'https://staging-api.supersourcing.com/asset-management-service/api/v1/',
        job_base_url: 'https://staging-api.supersourcing.com/job-management-service/api/v1/',
        role_base_url: 'https://staging-api.supersourcing.com/role-management-service/api/v1/',
        assessment_base_url: 'https://n1jlb84ac1.execute-api.ap-south-1.amazonaws.com/staging/api/v1/assessment/',
        job_interaction_base_url: 'https://staging-api.supersourcing.com/job-interaction-service/api/v1/',
        job_int_base_url: 'https://staging-api.supersourcing.com/job-interaction-service/api/v1/',
        public_resume_base_url: 'https://staging.developers.supersourcing.com/public-resume/',
        activity_leave_base_url : ' https://632x4er4of.execute-api.ap-south-1.amazonaws.com/staging/',
        notification_base_url: 'https://chatstaggingapi.supersourcing.com/api/v1/',
        socket_base_url : 'https://chatstaggingapi.supersourcing.com/',
        frontend_url: 'https://staging.developers.supersourcing.com/',
        currentEnv: 'https://staging.admin.supersourcing.com/',
        LINKEDIN_SIGNUP_REDIRECT_URL: 'auth/signup',
        LINKEDIN_LOGIN_REDIRECT_URL: 'auth/login',
        awsRequestRegex : /supersourcing-(video|doc|img)-dev.s3.ap-south-1.amazonaws.com/,
        aiInterviewer: 'https://interview.supersourcing.com/auth/login',
        time_sheet_management: 'undefined',
        resource_management_orchestrator: 'https://632x4er4of.execute-api.ap-south-1.amazonaws.com/staging/api/v1/',


    };
